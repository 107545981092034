
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IListParamsSelected, IListUserByGroupResponse } from '../../interfaces/list/list.interface'
import './listUser.css'
export interface ListUserProps {
   data: IListUserByGroupResponse[]
   add: (data: IListParamsSelected) => void
   fields: IListParamsSelected[]
}

export const ListUser: React.FC<ListUserProps> = ({
    data, 
    add,
    fields
}) => {

    const handleSelected = (data: IListParamsSelected) => {        
        add(data)
    }

    const truncateText = (text: string, length: number): string => {
        if(text.length > length) {
            return text.substring(0, length) + '...'
        }
        return text
    }

    const active = (user_id: number, group_id: number): string => {
        const find = fields.find((item) => item.user_id === user_id && item.group_id === group_id)
        if(find) {
            return 'text-focus'
        }
        return ''
    }

    const renderTooltip = (props: any, text: string) => (
        <Tooltip {...props}>{text}</Tooltip>
    );
   
    return (
        <div className="list-user">
            <div className="member">
               <span className='text-focus'>Membros</span>
               {data.map((member, index) => (             
                <OverlayTrigger
                    key={index}
                    placement='top-start'
                    overlay={(props) => renderTooltip(props, member.user_name)}
                >
                    <p className={active(member.user_id, member.group_id)} key={index} onClick={() => {handleSelected({ 
                        group_id: member.group_id, 
                        group_name: member.group_name,
                        user_id: member.user_id,
                        user_name: member.user_name, 

                    })}}>{truncateText(member.user_name, 40) }</p>
                </OverlayTrigger>                        
               ))}              
            </div>
            <div className="company">
                <span className='text-focus'>Empresa</span>                
                {data.map((company, index) => (
                    <OverlayTrigger
                        key={index}
                        placement='top-start'
                        overlay={(props) => renderTooltip(props, company.company_name)}
                    >
                        <p className={active(company.user_id, company.group_id)} key={index} onClick={() => handleSelected({
                        group_id: company.group_id,
                        group_name: company.group_name,
                        user_id: company.user_id,
                        user_name: company.user_name,                        
                    })}>{truncateText(company.company_name, 40)}</p>                    
                    </OverlayTrigger>                    
                ))}               
            </div>                        
        </div>
    )
}