import "./userForm.css";
import React, { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import {
  blobToBase64,
  clearBase64,
  getOnlyNumberOfCpfOrCnpj,
  getOnlyNumberPhone,
  isValidCnpj,
  sendToast,
  validateCPf,
} from "../../../../utils/Helper";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, FormGroup, Row } from "react-bootstrap";
import { FormCustom } from "../../../../components/Form";
import { useTranslation } from "react-i18next";
import { useGroup } from "../../../../hooks/useGroup";
import { useGuest } from "../../../guests/hook/useGuests";
import { ISelectDefault } from "../../../../interfaces/form/form.interface";
import { listUserByGroup } from "../../../../api/list/list";
import { FieldInputUser } from "../../../../components/admin/fieldInput";
import { useCompany } from "../../company/hook/useCompany";
import { useRole } from "../../role/hook/useRole";
import { useChair } from "../../chair/hook/useChair";
import { IPhotoSend } from "../../../../interfaces/generic/generic.interface";
import { createUser, getUser, updateUser } from "../../../../api/user/User";
import {
  ICreateUserParams,
  IEditUserParams,
  IGetUserInfo,
  IUserGroupsInfoUser,
} from "../../../../interfaces/user/IUser";

interface IUserFormProps {
  handleGoback?: () => void;
  emitCreatedOrUpdateUser?: (e: boolean) => void;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; //10MB

const userFormSchema = z.object({
  name: z.string().nonempty("form_name_required"),
  groupDefault: z.coerce.number(),
  phone: z
    .string()
    .nonempty("form_phone_required")
    .transform((phone) => getOnlyNumberPhone(phone)),
  password: z.string().optional(),
  email: z.string().nonempty("form_email_required").email("form_email_valid"),
  file_list: z
    .custom<FileList>()
    .transform((file) => file.length > 0 && file.item(0))
    .refine((file) => !file || (!!file && file.size <= MAX_FILE_SIZE), {
      message: "O arquivo de ter no maximo 10MB",
    })
    .refine((file) => !file || (!!file && file.type?.startsWith("image")), {
      message: "Apenas imagem são permitidos",
    }),
  dateOfBirth: z.string().nonempty("form_birth_day_required"),
  language: z
    .string({ required_error: "form_language_required" })
    .nonempty("form_language_required"),
  cpf: z
    .string({ required_error: "form_cnpj_cpf_required" })
    .nonempty("form_cnpj_cpf_required")
    .refine((cpf) => {
      if (cpf.replace(/\D/g, "").length > 11) return isValidCnpj(cpf);
      return validateCPf(cpf);
    }, "form_cnpj_cpf_valid")
    .transform((cpf) => getOnlyNumberOfCpfOrCnpj(cpf)),
  id_guest: z.coerce.number().optional().nullable(),
  id_group_guest: z.coerce.number().optional(),
  id_user_guest: z.coerce.number().optional(),
  status: z.string().nonempty("form_status_required"),
  nexusAt: z.string().optional(),
  userGroup: z.array(
    z.object({
      id_group: z.coerce
        .number({ required_error: "form_group_required" })
        .refine(
          (idGroup) => {
            if (!idGroup) return false;
            return true;
          },
          { message: "form_group_required" }
        ),
      id_company: z.coerce.number({ required_error: "Compania é obrigatório" }),
      id_chair: z.coerce.number({ required_error: "Cadeira é obrigatório" }),
      id_sub_chair: z.coerce.number().optional(),
      id: z.coerce.number().optional(),
      id_user_guest: z.coerce.number().optional(),
      id_group_guest: z.coerce.number().optional(),
      type: z.coerce.number({ required_error: "Tipo é obrigatório" }),
    })
  ),
});

export type userFormData = z.infer<typeof userFormSchema>;

export const UserForm: React.FC<IUserFormProps> = ({
  handleGoback,
  emitCreatedOrUpdateUser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { selectGroup } = useGroup();
  const { companySelect } = useCompany();
  const { roleSelect } = useRole();
  const { chairSelect } = useChair();
  const { members, membersOptions } = useGuest();

  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [userSelect, setUserSelect] = useState<ISelectDefault[]>([]);
  const [idGroupGuest, setIdGroupGuest] = useState<string>("");
  const [idGuest, setIdGuest] = useState<string>("");
  const [user, setUser] = useState<IGetUserInfo>({} as IGetUserInfo);
  const [indexDisabled, setIndexDisable] = useState<number[]>([]);

  const { groupId, userId } = useParams();

  const isEdit = Boolean(userId);
  const userform = useForm<userFormData>({
    resolver: zodResolver(userFormSchema),
  });

  const { control, setValue, reset, handleSubmit } = userform;

  const { append, remove, fields } = useFieldArray({
    control,
    name: "userGroup",
  });

  const handleIdgroupGuest = (value: string) => {
    setValue("id_group_guest", +value);
    setIdGroupGuest(value);
  };

  const getUserById = async () => {
    getUser(+groupId!, +userId!).then((response) => {
      setUser(response.data);
    });
  };

  const updateIndexDisabled = (userGroups: IUserGroupsInfoUser[]) => {
    const newIndex = userGroups.map((item, index) => {
      return index;
    });

    setIndexDisable(newIndex);
  };

  const setValueForm = () => {
    setTimeout(() => {
      setValue("cpf", user.cpf);
      setValue("email", user.email);
      setValue("language", user.lang);
      setValue("phone", user.phone);
      setValue("name", user.name);
      setValue("dateOfBirth", user.date_of_birth);
      setValue("nexusAt", user.nexusAt);
      const indexs: number[] = [];
      user.userGroups?.forEach((item, index) => {
        indexs.push(index);
        append({
          id_chair: item.chair_id,
          id_company: item.company_id,
          type: item.role_id,
          id_group: item.group_id,
          id_sub_chair: item.sub_chair_id ?? undefined,
          id: item.id,
          id_group_guest: item.id_group_guest,
          id_user_guest: item.id_user_guest,
        });
      });
      setIndexDisable(indexs);
      const defaultGroup = user.userGroups?.find(
        (group) => group.is_default === true
      );
      setValue("groupDefault", defaultGroup?.group_id!);
      setValue("status", user.status);
    }, 2000);
  };

  const handleIdGuest = (id: string) => {
    setIdGuest(id);

    if (!id) setIsDisable(true);

    if (id) {
      setIsDisable(false);
      const indexMember = members.findIndex((item) => item.guest_id === +id);
      if (indexMember !== -1) {
        const member = members[indexMember];
        setValue("name", member.guest_name);
        setValue("email", member.guest_email);
        setValue("phone", member.phone);
        setValue("id_group_guest", member.group_id);
        setValue("groupDefault", member.group_id);
        setValue("userGroup.0.id_group", member.group_id);

        setValue("id_guest", +id);
        setIdGroupGuest(member.group_id.toString());
        setTimeout(() => {
          setValue("id_user_guest", member.id_user);
        }, 100);
      }
    } else {
      reset();
    }
  };

  const loadDefaultGroup = useCallback(() => {
    setValue("groupDefault", +groupId!);
  }, [groupId]);

  const loadUserGroupFirst = useCallback(() => {
    append({
      id_chair: 2,
      id_company: 2,
      id_group: +groupId!,
      type: 2,
      id_sub_chair: undefined,
    });
  }, []);

  const addGroup = (e: any) => {
    append(e);
  };

  const removeGroup = (index: number) => {
    remove(index);
  };

  const onSubmitUser = async (data: userFormData) => {
    const {
      cpf,
      dateOfBirth,
      email,
      file_list,
      groupDefault,
      language,
      name,
      password,
      phone,
      userGroup,
      id_group_guest,
      id_guest,
      id_user_guest,
      status,
      nexusAt,
    } = data;
    const validatedGroupDefault = handleValidatedDefaultGroup(data);
    if (!validatedGroupDefault) return;

    const base64photo: IPhotoSend = {} as IPhotoSend;

    if (file_list) {
      const base64 = await blobToBase64(file_list);
      base64photo.file = clearBase64(base64 as string);
      base64photo.name = file_list.name;
      base64photo.size = file_list.size;
    }

    if (isEdit) {
      const { data } = await fetchUpdateUser({
        cpf,
        dateOfBirth,
        email,
        groupDefault,
        language,
        name,
        password,
        phone,
        photo: Object.hasOwn(base64photo, "file") ? base64photo : null,
        userGroup: userGroup.map((item) => {
          return {
            id_chair: item.id_chair,
            id_company: item.id_company,
            id_group: item.id_group,
            type: item.type,
            id_sub_chair: item.id_sub_chair ? item.id_sub_chair : undefined,
            id: item.id,
            id_user_guest: item.id_user_guest,
            id_group_guest: item.id_group_guest,
          };
        }),
        status,
        nexusAt,
      });
      if (emitCreatedOrUpdateUser) emitCreatedOrUpdateUser(true);
      updateIndexDisabled(data.data.userGroups);
      return;
    }

    if (!isEdit) {
      if (!password) {
        sendToast({ message: "Por favor informe uma senha!", type: "error" });
        return;
      }

      await fetchCreateUser({
        cpf,
        dateOfBirth,
        email,
        groupDefault,
        language,
        name,
        password: password!,
        phone,
        photo: Object.hasOwn(base64photo, "file") ? base64photo : null,
        userGroup: userGroup.map((item) => {
          return {
            id_chair: item.id_chair,
            id_company: item.id_company,
            id_group: item.id_group,
            type: item.type,
            id_sub_chair: item.id_sub_chair ? item.id_sub_chair : undefined,
          };
        }),
        id_group_guest,
        id_guest: id_guest ? id_guest : undefined,
        id_user_guest,
        status,
        nexusAt,
      });

      if (emitCreatedOrUpdateUser) emitCreatedOrUpdateUser(true);
    }
  };

  const handleValidatedDefaultGroup = (data: userFormData): boolean => {
    const index = data.userGroup.findIndex(
      (item) => item.id_group === data.groupDefault
    );

    if (index === -1) {
      sendToast({ message: t("error_group_default"), type: "error" });
      return false;
    }

    return true;
  };

  const fetchUpdateUser = async (data: IEditUserParams) => {
    return await updateUser({
      groupId: +groupId!,
      userId: +userId!,
      data: data,
    });
  };

  const fetchCreateUser = async (data: ICreateUserParams) => {
    const { status } = await createUser(data, +groupId!);

    if (status) {
      reset();
    }
  };

  const gobackEdit = () => {
    navigate(`../`);
  };

  useEffect(() => {
    if (idGroupGuest) {
      listUserByGroup(+idGroupGuest).then((response) => {
        const select = response.data.map((item) => {
          return {
            label: item.user_name,
            value: item.user_id,
          };
        });

        setUserSelect(select);
      });
    }
  }, [idGroupGuest]);

  useEffect(() => {
    if (!isEdit) {
      loadDefaultGroup();
      loadUserGroupFirst();
    }

    if (isEdit) {
      getUserById();
    }
  }, []);

  useEffect(() => {
    if (user) {
      setValueForm();
    }
  }, [user]);

  return (
    <FormProvider {...userform}>
      <Form onSubmit={handleSubmit(onSubmitUser)}>
        <Container fluid className="vh-100">
          <Row>
            <Col>
              <div className="float-end mb-3 mr-1 m-1 input-select__id_guest">
                <Form.Group className="nexus_input">
                  <Form.Label htmlFor="nexusAt">{t("nexusAt")}</Form.Label>
                  <FormCustom.Control
                    type="date"
                    showError={true}
                    field="nexusAt"
                    id="nexusAt"
                  />
                </Form.Group>

                {!isEdit && (
                  <Form.Group className="input_guest">
                    <Form.Label htmlFor="id_guest">
                      {t("import_guest_information")}
                    </Form.Label>
                    <FormCustom.Select
                      id="id_guest"
                      field="id_guest"
                      onChange={(e) => {
                        handleIdGuest(e.target.value);
                      }}
                      options={membersOptions}
                    />
                  </Form.Group>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormCustom.Photo url={user.urlPhoto} field="file_list" />
              <FormCustom.ErrorMessage field="photo" />
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="name">{t("name")}</Form.Label>
                <FormCustom.Control
                  type="text"
                  field="name"
                  id="name"
                  showError={true}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="phone">{t("phoneLabel")}</Form.Label>
                <FormCustom.Phone
                  type="text"
                  placeholder={t("phonePlacehold")}
                  field="phone"
                  id="phone"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="password">{t("password")}</Form.Label>
                <FormCustom.Control
                  type="password"
                  placeholder={t("password")}
                  field="password"
                  id="password"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="email">{t("email")}</Form.Label>
                <FormCustom.Control
                  type="email"
                  placeholder={t("email")}
                  field="email"
                  id="email"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="dateOfBirth">
                  {t("dateOfBirth")}
                </Form.Label>
                <FormCustom.Control
                  type="date"
                  placeholder={t("dateOfBirth")}
                  field="dateOfBirth"
                  id="dateOfBirth"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="language">{t("language")}</Form.Label>
                <FormCustom.Lang field="language" id="language" />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="cpf">{t("cpf_cnpj")}</Form.Label>
                <FormCustom.Documents
                  type="text"
                  placeholder={t("cpf_cnpj")}
                  field="cpf"
                />
                <FormCustom.ErrorMessage field="cpf" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="status">{t("status")}</Form.Label>
                <FormCustom.Status field="status" />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="groupDefault">
                  {t("groupDefault")}
                </Form.Label>
                <FormCustom.Select
                  showError={true}
                  field="groupDefault"
                  id="groupDefault"
                  options={selectGroup}
                />
              </Form.Group>
            </Col>
          </Row>
          {fields.map((field, index) => (
            <FieldInputUser
              groups={selectGroup}
              chairs={chairSelect}
              companys={companySelect}
              roles={roleSelect}
              indexForm={index}
              key={index}
              subChairSelected={
                field.id_sub_chair ? field.id_sub_chair : undefined
              }
              callbackAdd={addGroup}
              callbackRemove={removeGroup}
              isEdit={isEdit}
              indexsDisabled={indexDisabled}
              userFetch={user}
            />
          ))}
          <FormCustom.ErrorMessage field="userGroup" />
          {!isEdit && (
            <Row className="input-padrinho-row">
              <Col>
                <FormGroup>
                  <Form.Label>{t("godfather_group")}</Form.Label>
                  <FormCustom.Select
                    field="id_group_guest"
                    options={selectGroup}
                    onChange={(e) => handleIdgroupGuest(e.target.value)}
                    value={idGroupGuest}
                    disabled={isDisable}
                    className="input-padrinho-group"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Form.Label>{t("godfather")}</Form.Label>
                  <FormCustom.Select
                    options={userSelect}
                    field="id_user_guest"
                    disabled={isDisable}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <Row className="justify-content-md-end">
            <Col xl={3} sm={3} md={3} className="mt-3">
              <Button
                variant="primary"
                type="submit"
                className="float-end mb-3 mr-1 m-1"
              >
                {t("submit")}
              </Button>
              <Button
                variant="secondary"
                type="button"
                className="float-end mb-3 mr-1 m-1"
                onClick={handleGoback ? handleGoback : gobackEdit}
              >
                {t("goback")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </FormProvider>
  );
};
