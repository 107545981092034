import { Badge, Col, Dropdown, FormLabel, Row } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import { Power, Person, Envelope, Key } from 'react-bootstrap-icons'
import { useAuth } from '../../contexts/Auth'
import { useGuard } from '../../contexts/Guard'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Select from 'react-select'
import { dateFormat, sendToast } from '../../utils/Helper'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IChangeGroup } from '../../interfaces/header/IChangeGroup'
import '../../styles/layout/header.css'
import { permissionAdminEnum } from '../../enum/permission-admin.enum'


const Header: React.FC = () => {
    const { t } = useTranslation()
    const { Logout, groups } = useAuth()
    const { ChangeGroup, infoUser, CheckPermissionWithArray } = useGuard()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const urlAvatarDefault = process.env.REACT_APP_PUBLIC_URL + '/assets/img/profile/avatar.png'

    const handleLogout = () => {
        Logout()
        navigate("/")
    }
    const changeGroup = (e: IChangeGroup) => {
        const redirect = ChangeGroup(pathname, e)
        navigate(redirect)
        sendToast({ message: t('changedGroup') + e.label, type: 'success' })
    }

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAdminOpen, setIsAdminOpen] = useState(true);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleAdmin = () => {
        setIsAdminOpen(isAdminOpen);
    };

    return (
        <Navbar className='shadow' expand="xl" bg="dark" variant="dark">
            <Container fluid>
                <Navbar.Brand as={Link} to="/">
                    <Image
                        width="170"
                        height="35.08"
                        alt="logo"
                        src={`${process.env.REACT_APP_PUBLIC_URL}/assets/img/login/nexus-logo.png`}
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMenu} />
                <Navbar.Collapse id="responsive-navbar-nav" in={isMenuOpen}>
                    <Nav className="me-auto text-nowrap">
                        <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}`} onClick={closeMenu}>
                            {t('dashboard')}
                        </Nav.Link> : ''
                        {CheckPermissionWithArray(['weekly-meetings.index', 'weekly-meetings.store', 'weekly-meetings.presence']) ?
                            <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/reunioes`} onClick={closeMenu}>
                                {t('weeklyBusiness')}
                            </Nav.Link> : ''
                        }
                        {CheckPermissionWithArray(['training_meetings_menu']) ?
                            <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/treinamentos`}>
                                {t('trainingMeeting')}
                            </Nav.Link> : ''
                        }
                        {
                            CheckPermissionWithArray(['guests.index', 'guests.store', 'guests.re-invite']) ?
                                <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/convidados`}>
                                    {t('guests')}
                                </Nav.Link> : ''
                        }
                        {
                            CheckPermissionWithArray(['indications.index', 'indications.store', 'indications.update']) ?
                                <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/indicacoes`}>
                                    {t('indications')}
                                </Nav.Link> : ''
                        }
                        {
                            CheckPermissionWithArray(['business.index']) ?
                                <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/bussines`}>
                                    {t('business')}
                                </Nav.Link> : ''
                        }
                        {CheckPermissionWithArray(['leadership_meetings_menu', 'leadership_meetings_store', 'leadership_meetings_delete']) ?
                            <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/leadership`}>
                                {t('leadership_meeting')}
                            </Nav.Link> : ''
                        }
                        {CheckPermissionWithArray(['rdn.index', 'rdn.store', 'rdn.delete']) ?
                            <Nav.Link as={Link} to={`/grupo/${infoUser.group_selected.id}/rdn`} onClick={closeMenu}>
                                {t('rdn')}
                            </Nav.Link> : ''
                        }
                        {CheckPermissionWithArray(['report.apresentation', 'report.members', 'report.ranking', 'report.birthday']) ?
                            <NavDropdown title={t('reports')} id="collasible-nav-dropdown" menuVariant="dark">
                                {CheckPermissionWithArray(['report.apresentation']) ?
                                    <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/relatorios/apresentacao`} onClick={closeMenu}>
                                        {t('apresentation')}
                                    </NavDropdown.Item> : <></>
                                }
                                {CheckPermissionWithArray(['report.members']) ?
                                    <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/relatorios/membros`} onClick={closeMenu}>
                                        {t('members_guest')}
                                    </NavDropdown.Item> : <></>
                                }
                                {CheckPermissionWithArray(['report.ranking']) ?
                                    <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/relatorios/ranking`} onClick={closeMenu}>
                                        {t('ranking')}
                                    </NavDropdown.Item> : <></>
                                }
                                {CheckPermissionWithArray(['report.birthday']) ?
                                    <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/relatorios/aniversariantes`} onClick={closeMenu}>
                                        {t('birthday')}
                                    </NavDropdown.Item> : <></>
                                }
                            </NavDropdown> : <></>
                        }
                    </Nav>
                    <Nav>
                        <Container className='m-0 mt-2 mt-lg-0 px-lg-3 px-0'>
                            <Row onClick={toggleAdmin}>
                                <Col sm={{ span: 8 }} md={{ span: 8 }} xs={{ span: 8 }} className='custom-w'>
                                    <Image
                                        roundedCircle={true}
                                        width="40px"
                                        height="40px"
                                        src={infoUser.user.photo_url ? infoUser.user.photo_url : urlAvatarDefault}
                                    />
                                </Col>
                                <Col sm={{ span: 8 }} md={{ span: 8 }} className='my-auto'>
                                    <Row>
                                        <Col sm={{ span: 12 }} md={{ span: 9 }}>
                                            <Navbar.Collapse id="navbar-dark-example" in={isAdminOpen}>
                                                <Nav>
                                                    <NavDropdown
                                                        id="nav-dropdown-dark-example"
                                                        title={infoUser.user.name}
                                                        menuVariant="dark"
                                                        align="end"
                                                    >
                                                        <NavDropdown.ItemText className="nav-dropdown">
                                                            <p className="my-1">
                                                                <span className="pe-2">
                                                                    {t('group')}:
                                                                </span>
                                                                <Badge bg="warning" text="primary">
                                                                    {infoUser.group_selected.name}
                                                                </Badge>
                                                            </p>
                                                            <p className="my-1">
                                                                <span className="pe-2">
                                                                    {t('company')}:
                                                                </span>
                                                                <Badge bg="warning" text="primary">
                                                                    {infoUser.company_selected.name}
                                                                </Badge>
                                                            </p>
                                                            <p className="my-1">
                                                                <span className="pe-2">
                                                                    {t('chair')}:
                                                                </span>
                                                                <Badge bg="warning" text="primary">
                                                                    {infoUser.chair_selected.name}
                                                                </Badge>
                                                            </p>
                                                            <p className="my-1">
                                                                <span className="pe-2">
                                                                    {t('start')}:
                                                                </span>
                                                                <Badge bg="warning" text="primary">
                                                                    {dateFormat(infoUser.group_selected.createdAt || infoUser.company_selected.created_at)}
                                                                </Badge>
                                                            </p>
                                                        </NavDropdown.ItemText>
                                                        <Dropdown.Divider />

                                                        <NavDropdown.ItemText className="nav-dropdown">
                                                            <FormLabel className="text-center text-primary">
                                                                {t('changeGroup')}
                                                            </FormLabel>
                                                            <Select
                                                                onChange={(e) => changeGroup({ label: e?.label!, value: e?.value! })}
                                                                className="basic-single"
                                                                classNamePrefix={t('selectGroup')}
                                                                isDisabled={false}
                                                                isLoading={false}
                                                                isClearable={true}
                                                                isRtl={false}
                                                                defaultValue={{ label: infoUser.group_selected.name, value: infoUser.group_selected.id }}
                                                                isSearchable={true}
                                                                options={groups.map((group) => {
                                                                    return { label: group.name, value: group.id }
                                                                })}
                                                                name="group"
                                                                styles={{
                                                                    control: (styles) => ({ ...styles, color: "grey" }),
                                                                    option: (styles) => ({ ...styles, backgroundColor: '#2f2f2f', color: "white" }),
                                                                    menuList: (styles, state) => ({
                                                                        ...styles,
                                                                        paddingTop: 0,
                                                                        paddingBottom: 0,
                                                                    })
                                                                }} />
                                                        </NavDropdown.ItemText>
                                                        <Dropdown.Divider />

                                                        {CheckPermissionWithArray(permissionAdminEnum) ?
                                                            <>
                                                                <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/admin/usuarios`} className="nav-dropdown" onClick={closeMenu}>
                                                                    <Key color="#ffc600" size={20} className="mb-1 me-2" />
                                                                    {t('admin')}
                                                                </NavDropdown.Item>
                                                            </>
                                                            :
                                                            <></>
                                                        }

                                                        <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/perfil/${infoUser.user.id}`} className="nav-dropdown" onClick={closeMenu}>
                                                            <Person color="#ffc600" size={20} className="mb-1 me-2" />
                                                            {t('profile')}
                                                        </NavDropdown.Item>

                                                        <NavDropdown.Item as={Link} to={`/grupo/${infoUser.group_selected.id}/contato`} className="nav-dropdown" onClick={closeMenu}>
                                                            <Envelope color="#ffc600" size={20} className="mb-1 me-2" />
                                                            {t('contact')}
                                                        </NavDropdown.Item>

                                                        <NavDropdown.Item className="nav-dropdown" onClick={handleLogout}>
                                                            <Power color="#ffc600" size={20} className="mb-1 me-2" />
                                                            {t('logout')}
                                                        </NavDropdown.Item>
                                                    </NavDropdown>
                                                </Nav>
                                            </Navbar.Collapse>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    )
}

export default Header