import "./styles/rdn.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useGuard from "../../contexts/Guard";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Presence } from "../../components/presence/Presence";
import { ListUser } from "../../components/listUser/ListUser";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { listAllGroup, listReason, listUserByGroup } from "../../api/list/list";
import {
  IListParamsSelected,
  IListUserByGroupResponse,
  listAllGroupResponse,
} from "../../interfaces/list/list.interface";

import { IResponsePaginatorShow } from "../../interfaces/rdn/rdn.interface";
import { ITableComponentCols } from "../../components/Table/TableComponent";
import { ModalComponent } from "../../components/ModalComponent";
import { IReasonResponse } from "../../interfaces/reason/reason.interface";
import { blockContributionDate, getDateLocale } from "../../utils/Helper";
import { FormCustom } from "../../components/Form";
import { IResponseGeneric } from "../../interfaces/generic/generic.interface";
import { TableCustomComponent } from "../../components/Table";
import { ITableSearchFormData } from "../../components/Table/TableSearchComponent";
import { IPaginationsCallback } from "../../components/Table/PaginationComponent";
import { deleteTrainingMeeting, showTrainingMeeting, storeTrainingMeetingV2, verificationTrainingMeeting } from "../../api/trainingMeeting/training-meeting";


const trainingMeetingCreateSchema = z.object({
  date: z.string().nonempty({ message: 'Data é obrigatória' }).refine((data) => blockContributionDate(new Date(data)), { message: 'Não é possível criar RDN com mais de 1 mês de atraso!' }),
  groupId: z.coerce.number().nullable(),
  guests: z.array(
    z.object({
      user_id: z.coerce.number(),
      group_id: z.coerce.number(),
      user_name: z.string(),
      group_name: z.string(),
    })
  ),
});

const trainingMeetingDeleteSchema = z.object({
  id_reason: z.coerce.number(),
})

export type ICreateTrainingMeetingFormData = z.infer<typeof trainingMeetingCreateSchema>;
export type IDeleteTrainingMeetingFormData = z.infer<typeof trainingMeetingDeleteSchema>;

const TrainingMeetingV2: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showModalDuplIcated, setShowModalDuplIcated] = useState(false);
  const { infoUser, CheckPermissionWithArray } = useGuard();
  const { t } = useTranslation();
  const { groupId } = useParams();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [groups, setGroups] = useState<listAllGroupResponse[]>([]);
  const [userMember, setUserMember] = useState<IListUserByGroupResponse[]>([]);
  const [trainingMeeting, setTrainingMeeting] = useState<IResponseGeneric<IResponsePaginatorShow>>({} as IResponseGeneric<IResponsePaginatorShow>);
  const [reasons, setReasons] = useState<IReasonResponse[]>([]);
  const [trainingMeetingSearch, setTrainingMeetingSearch] = useState('')

  const [trainingMeetingDelete, setTrainingMeetingDelete] = useState<IResponsePaginatorShow>({} as IResponsePaginatorShow);

  const handleOpenModalRemove = (data: IResponsePaginatorShow) => {
    setTrainingMeetingDelete(data)
    setShowModal(true)
  }

  const handleCloseModalDuplicated = () => {
    setShowModalDuplIcated(false)
  }

  const getReasons = () => {
    listReason().then((response) => {
      setReasons(response.data)
      const first = [...response.data].pop()!
      setValueDelete('id_reason', first.id)
    })
  }

  const columns: ITableComponentCols[] = [
    {
      title: 'Membros',
      render: (data: IResponsePaginatorShow) => {
        return (
          <span>{data.users}</span>
        )
      }
    },
    {
      title: 'Dia da reunião',
      render: (data: IResponsePaginatorShow) => (
        <span>{getDateLocale(data.meeting_date, 'pt-BR')}</span>
      )
    },
    {
      title: 'Ações',
      permissions: ['training__meeting.delete'],
      render: (data: IResponsePaginatorShow) => (
        <>
          { (CheckPermissionWithArray(['training__meeting.delete']) && !data.old )&& (
            <Button
              className="mr-1 m-1"
              variant="danger"
              onClick={() => handleOpenModalRemove(data)}
            >
              Deletar
            </Button>
          )}
        </>
      )
    },
  ]

  const handleActionsModal = (action: boolean) => {
    if (!action) {
      handleCloseModal()
      return
    }

    const data = trainingMeetingDeleteForm.getValues()

    deleteTrainingMeeting({
      id_trainingMeeting: trainingMeetingDelete.id,
      id_user: infoUser.user.id,
      id_reason: data.id_reason,
      id_group: infoUser.group_selected.id
    }).then((r) => {
      getTrainingMeeting()
      handleCloseModal()
    })

  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const getTrainingMeeting = () => {
    showTrainingMeeting({
      id_group: +groupId!,
      page: 1
    }).then((response) => {
      setTrainingMeeting(response.data);
    })
  }

  const callbackSerchTrainingMeeting = ({ search }: ITableSearchFormData) => {
    setTrainingMeetingSearch(search)
    showTrainingMeeting({
      id_group: +groupId!,
      page: 1,
      name: search
    }).then((response) => {
      setTrainingMeeting(response.data);
    })
  }

  const callbackPaginatorTrainingMeeting = ({ page }: IPaginationsCallback) => {
    showTrainingMeeting({
      id_group: +groupId!,
      page,
      name: trainingMeetingSearch
    }).then((response) => {
      setTrainingMeeting(response.data);
    })
  }

  const setCreatedUserTrainingMeeting = () => {
    append({
      user_id: infoUser.user.id,
      group_id: infoUser.group_selected.id,
      user_name: infoUser.user.name,
      group_name: infoUser.group_selected.name,
    })
  }

  const trainingMeetingDeleteForm = useForm<IDeleteTrainingMeetingFormData>({
    resolver: zodResolver(trainingMeetingDeleteSchema),
  })

  const {
    handleSubmit: handleSubmitDelete,
    register: registerDelete,
    setValue: setValueDelete,
  } = trainingMeetingDeleteForm

  const trainingMeetingForm = useForm<ICreateTrainingMeetingFormData>({
    resolver: zodResolver(trainingMeetingCreateSchema),
    defaultValues: {
      groupId: infoUser.group_selected.id,
    },
  });

  const listGroup = () => {
    listAllGroup().then((response) => {
      setGroups(response.data);
      setTimeout(() => {
        setValue("groupId", infoUser.group_selected.id);
      }, 1000);
    });
  };

  const listUsers = (group_id: number) => {
    listUserByGroup(group_id).then((response) => {
      setUserMember(response.data);
    });
  };

  const { handleSubmit, register, watch, control, setValue, reset } = trainingMeetingForm;

  const watchGroupId = watch("groupId");

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "guests",
  });

  const handleAdd = (data: IListParamsSelected) => {
    const isExist = fields.findIndex((item) => item.user_id === data.user_id && item.group_id === data.group_id);
    if (isExist !== -1) {
      handleRemove(isExist);
      return;
    }
    append(data);
  };

  const handleRemove = (index: number) => {
    if (index === 0) return
    remove(index);
  };

  const handleVerificationTrainingMeeting = (form: ICreateTrainingMeetingFormData) => {
    const guests = form.guests.map((item) => {
      return {
        id_user: item.user_id,
        id_group: item.group_id,
      }
    })

    verificationTrainingMeeting({
      id_group: infoUser.group_selected.id,
      id_user: infoUser.user.id,
      meeting_date: form.date,
      guests
    }).then((response) => {
      if (response.data.rdnExist) {
        setShowModalDuplIcated(true)
        return
      }

      onSubimit(form)
    })
  }

  const onSubimit = (form: ICreateTrainingMeetingFormData) => {
    setShowModalDuplIcated(false)
    const guests = form.guests.map((item) => {
      return {
        id_user: item.user_id,
        id_group: item.group_id,
      }
    })

    storeTrainingMeetingV2({
      id_group: infoUser.group_selected.id,
      id_user: infoUser.user.id,
      meeting_date: form.date,
      guests
    }).then(() => {
      reset()
      setCreatedUserTrainingMeeting()
      getTrainingMeeting()
    })
  }

  const handleOnSubimitDelete = (form: IDeleteTrainingMeetingFormData) => {
    console.log(form)
  }

  useEffect(() => {
    listGroup();
    listUsers(+groupId!);
    setCreatedUserTrainingMeeting()
    getTrainingMeeting()
    getReasons()
  }, [groupId]);

  useEffect(() => {
    if (watchGroupId) {
      listUsers(+watchGroupId);
    }
  }, [watchGroupId]);

  return (
    <>
      <Container fluid className="p-4">
        <Row className="border border-secondary border-2 rounded pt-4">
          <Col>
            <Tabs
              activeKey={activeTab}
              onSelect={(index) => setActiveTab(+index!)}
            >
              <Tab eventKey={0} title="Listagem de Treinamento">
                <TableCustomComponent.Root>
                  <TableCustomComponent.Search callback={callbackSerchTrainingMeeting} />
                  <TableCustomComponent.Table
                    cols={columns}
                    data={trainingMeeting.data}
                    striped
                  />
                  <TableCustomComponent.Pagination callback={callbackPaginatorTrainingMeeting} {...trainingMeeting.meta} />
                </TableCustomComponent.Root>
              </Tab>
              {CheckPermissionWithArray(['training_meeting.create']) && (
                <Tab eventKey={1} title="Cadastrar Treinamento">
                  <Form onSubmit={handleSubmit(handleVerificationTrainingMeeting)}>
                    <FormProvider {...trainingMeetingForm}>
                      <div className="rdn-form">
                        <FormGroup className="form-date">
                          <label htmlFor="data">Data</label>
                          <Form.Control
                            type="date"
                            id="data"
                            {...register("date")}
                          />
                          <FormCustom.ErrorMessage field="date" />
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="presence">Presentes</label>
                          <Presence data={fields} remove={handleRemove} />
                        </FormGroup>
                        <FormGroup>
                          <label htmlFor="group">Grupo</label>
                          <Form.Select
                            className="form-select"
                            id="group"
                            {...register("groupId")}
                          >
                            {groups.map((group, index) => (
                              <option key={index} value={group.id}>
                                {group.name}
                              </option>
                            ))}
                          </Form.Select>
                        </FormGroup>
                        <FormGroup>
                          <ListUser
                            data={userMember}
                            add={handleAdd}
                            fields={fields}
                          />
                        </FormGroup>
                      </div>
                      <Row className="justify-content-md-end">
                        <Col xl={3} sm={3} md={3} className="mt-3">
                          <Button
                            variant="primary"
                            type="submit"
                            className="float-end mb-3 mr-1 m-1"
                          >
                            {t("submit")}
                          </Button>
                          <Button
                            variant="secondary"
                            type="button"
                            className="float-end mb-3 mr-1 m-1"
                          // onClick={handleGoBack}
                          >
                            {t("goback")}
                          </Button>
                        </Col>
                      </Row>
                    </FormProvider>
                  </Form>
                </Tab>
              )}

            </Tabs>
          </Col>
        </Row>
      </Container>
      {showModal && (
        <ModalComponent.Root
          show={showModal}
          onHide={handleCloseModal}
          size="lg"
          className="form-rdn-delete"
        >
          <ModalComponent.Content
            className="form-rdn-delete-content-wrapper"
          >
            <div className="form-rdn-delete-content">
              <h3>Escolha um motivo para excluir a Reunião Semanal</h3>
              <Container fluid className="form-rdn-wrapper">
                <Form onSubmit={handleSubmitDelete(handleOnSubimitDelete)}>

                  <Form.Select {...registerDelete('id_reason')}>
                    {reasons.map((reason, index) => (
                      <option key={index} value={reason.id}>{reason.name}</option>
                    ))}
                  </Form.Select>

                  <div className="form-rdn-delete-btn">
                    <ModalComponent.Actions
                      variant="primary"
                      className="form-rnd-delete-btn-width"
                      onClick={() => handleActionsModal(true)}
                    >
                      Salvar
                    </ModalComponent.Actions>
                    <ModalComponent.Actions
                      variant="danger"
                      className="form-rnd-delete-btn-width"
                      onClick={() => handleActionsModal(false)}
                    >
                      Cancelar
                    </ModalComponent.Actions>
                  </div>
                </Form>
              </Container>
            </div>
          </ModalComponent.Content>
        </ModalComponent.Root>
      )}
      {showModalDuplIcated && (
        <ModalComponent.Root
          show={showModalDuplIcated}
          onHide={handleCloseModalDuplicated}
          size="lg"
        >
          <ModalComponent.Content>
            <p className="rdn-modal-duplicate-title">Já existe uma Reunião de treinamentos com esse membro nesta mesma data, deseja continuar?</p>
          </ModalComponent.Content>


          <div className="rdn-modal-duplicate-actions">
            <ModalComponent.Actions
              variant="primary"
              onClick={() => onSubimit(trainingMeetingForm.getValues())}
              className="w-25"
            >
              Sim
            </ModalComponent.Actions>
            <ModalComponent.Actions
              variant="danger"
              onClick={() => handleCloseModalDuplicated()}
              className="w-25"
            >
              Não
            </ModalComponent.Actions>
          </div>

        </ModalComponent.Root>
      )}
    </>
  );
};

export default TrainingMeetingV2;
